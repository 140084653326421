import React from 'react';

import Edges from '../components/Edges';
import LogosBlock from '../components/LogosBlock';
import LinkBlocks from '../components/LinkBlocks';
import Seo from '../components/Seo';
import PageHeader from '../components/PageHeader';

const InternationalPage = (props) => {
    const { uri } = props;

    const pageTitle = 'International';

    return (
        <>
            <Seo title={pageTitle} description="In BC, there are many excellent opportunities for both international students and recent immigrants to pursue a career in trades." />
            <PageHeader title={pageTitle} breadcrumbs={[{ label: pageTitle, url: uri }]} />

            <div className="mx-auto max-w-7xl">
                <Edges size="md">
                    <p className="px-[20px] text-[#414141] lg:px-[0px]">
                        In BC, there are many excellent opportunities for both international students and recent immigrants to pursue a career in trades. To get the help you need please contact the institution you are
                        interested in attending directly. Links to their International Students pages are listed below:
                    </p>
                </Edges>
            </div>
            <Edges size="lg">
                <div className="md:px-[45px]">
                    <LogosBlock uri={uri} />
                </div>
            </Edges>
            <LinkBlocks />
        </>
    );
};

export default InternationalPage;
