import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";

import Edges from "../components/Edges";

export default function LinkBlocks() {
    return (
        <div className="z-1 relative bg-white">
            <Edges size="lg" className="md:px-[50px]">
                <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-12 ">
                    <p className="mb-[25px] tracking-wider text-[#414141]">
                        The BC Government has a website to help prospective international
                        students work through the process of studying in BC. Select a
                        language below to view the WelcomeBC website best suited for you.
                    </p>

                    <div className="flex flex-col flex-wrap items-center md:flex-row">
                        <a href="https://www.welcomebc.ca" target="_blank">
                            <StaticImage
                                className="mb-[30px] mr-[30px] w-[285px]"
                                src="../images/welcome.png"
                                alt="Welcome BC"
                            />
                        </a>
                    </div>

                    <p className="mb-[70px] tracking-wider text-[#414141]">
                        *Note, while the site loads in English, it can be viewed in another language by
                        selecting the language from the dropdown in the footer.
                    </p>
                </div>
            </Edges>
        </div>
    );
}
